<template>
  <div id="report-parkingDensity" class="report">
    <div class="filtrate">
      选择停车场：
      <el-select
          v-model="parkingLotId"
          placeholder="请选择"
          @change="refresh"
      >
        <el-option
            v-for="item in parkingLotList"
            :key="item.parkingLotId"
            :label="item.parkingLotName"
            :value="item.parkingLotId"
        >
        </el-option>
      </el-select>
    </div>
    <div class="parkingSpaceUpdate-parkingSpaceType">
      <div class="parkingSpaceUpdate statisticalAnalysis">
        <div class="title-filter">
          <h1>车位迭代更新分析</h1>
          <ul class="filter">
            <li>
              <el-date-picker
                  v-model="date"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="parkingSpaceUpdateChange"
              >
              </el-date-picker>
            </li>
          </ul>
        </div>
        <parkingSpaceUpdate ref="parkingSpaceUpdate" class="chartDetail"/>
      </div>
      <div class="parkingSpaceType statisticalAnalysis">
        <div class="title-filter">
          <h1>场景车位类型分析</h1>
        </div>
        <parkingSpaceType ref="parkingSpaceType" class="chartDetail"/>
      </div>
    </div>
    <div class="paymentPeriod">
      <div class="statisticalAnalysis">
        <div class="title-filter">
          <h1>支付时段分析</h1>
          <ul class="filter">
            <li>
              <div class="typeSelect">
                <span v-for="item of dateTypeList" :class="{active:dateType===item}" @click="changeDateType(item)">{{
                    item
                  }}</span>
              </div>
            </li>
          </ul>
        </div>
        <paymentPeriod ref="paymentPeriod" class="chartDetail"/>
      </div>
    </div>
    <div class="crossingDensity">
      <div class="statisticalAnalysis">
        <div class="title-filter">
          <h1>道口密度分析</h1>
        </div>
        <crossingDensity ref="crossingDensity" class="chartDetail"/>
      </div>
    </div>
    <div class="peopleFlow-peopleFlowRanking">
      <div class="peopleFlow statisticalAnalysis">
        <div class="title-filter">
          <h1>停车场人流统计</h1>
          <ul class="filter">
            <li>
              <div class="typeSelect">
                <span v-for="item of dateTypeList" :class="{active:peopleFlow.type===item}" @click="peopleFlow.type=item;peopleFlow_refresh()">{{
                    item
                  }}</span>
              </div>
            </li>
          </ul>
        </div>
        <peopleFlow ref="peopleFlow" @refreshRanking="peopleFlow_ranking" class="chartDetail"/>
      </div>
      <div class="peopleFlowRanking statisticalAnalysis">
        <peopleFlowRanking :list="peopleFlow.ranks" refs="ranking"/>
      </div>
    </div>
    <div class="passagewayStatistics-passagewayStatisticsRanking">
      <div class="passagewayStatistics statisticalAnalysis">
        <div class="title-filter">
          <h1>停车场进出口数量统计</h1>
          <ul class="filter">
            <li>
              <div class="typeSelect">
                <span v-for="item of dateTypeList" :class="{active:passagewayStatistics.type===item}" @click="passagewayStatistics.type=item;passagewayStatistics_refresh()">{{
                    item
                  }}</span>
              </div>
            </li>
          </ul>
        </div>
        <passagewayStatistics ref="passagewayStatistics" @refreshRanking="passagewayStatistics_ranking" class="chartDetail"/>
      </div>
      <div class="passagewayStatisticsRanking statisticalAnalysis">
        <passagewayStatisticsRanking :list="passagewayStatistics.ranks" refs="ranking"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    parkingSpaceUpdate: () => import("./parkingSpaceUpdate"),
    parkingSpaceType: () => import("./parkingSpaceType"),
    paymentPeriod: () => import("./paymentPeriod"),
    crossingDensity: () => import("./crossingDensity"),
    peopleFlow:()=>import("./peopleFlow"),
    peopleFlowRanking:()=>import("./peopleFlowRanking"),
    passagewayStatistics:()=>import("./passagewayStatistics"),
    passagewayStatisticsRanking:()=>import("./passagewayStatisticsRanking"),
  },
  data() {
    return {
      parkingLotId: "",
      date: "",
      dateTypeList: [
        "今日", "本月", "今年"
      ],
      dateType: "今日",
      peopleFlow:{
        ranks: [],
        type: "今日",
      },
      passagewayStatistics:{
        ranks: [],
        type: "今日",
      },
    }
  },
  computed:{
    parkingLotList(){
      return this.$store.state.select.parkingLotList;
    },
  },
  watch:{
    parkingLotList:{
      handler(list){
        if(list.length){
          this.parkingLotId = list[0].parkingLotId;
          setTimeout(()=>{
            this.refresh()
          },300)
        }
      },
      immediate:true
    },
  },
  methods: {
    peopleFlow_ranking(ranks){
      this.$set(this.peopleFlow,"ranks",ranks)
    },
    passagewayStatistics_ranking(ranks){
      this.$set(this.passagewayStatistics,"ranks",ranks)
    },
    peopleFlow_refresh(){
      this.$refs.peopleFlow.getOptions({
        parkingLotId: this.parkingLotId,
        type: this.peopleFlow.type
      })
    },
    passagewayStatistics_refresh(){
      this.$refs.passagewayStatistics.getOptions({
        parkingLotId: this.parkingLotId,
        type: this.passagewayStatistics.type
      })
    },
    parkingSpaceUpdateChange() {
      this.$refs.parkingSpaceUpdate.getOptions({
        parkingLotId: this.parkingLotId,
        startTime: this.date[0],
        endTime: this.date[1],
      });
    },
    changeDateType(dateType) {
      this.dateType = dateType;
      this.$refs.paymentPeriod.getOptions({
        parkingLotId: this.parkingLotId,
        dateType: this.dateType,
      });
    },
    refresh() {
      this.$nextTick(() => {
        this.parkingSpaceUpdateChange()
        this.$refs.parkingSpaceType.getOptions({
          parkingLotId: this.parkingLotId
        });
        this.changeDateType(this.dateType)
        this.$refs.crossingDensity.getOptions({
          parkingLotId: this.parkingLotId
        });
        this.peopleFlow_refresh()
        this.passagewayStatistics_refresh()
      })
    },
  }
}
</script>

<style lang="scss">
#report-parkingDensity {
  .filtrate {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.63rem;
  }

  .parkingSpaceUpdate-parkingSpaceType{
    display: flex;
    justify-content: space-between;
    height: 25rem;

    .parkingSpaceUpdate {
      width: 67%;
    }

    .parkingSpaceType {
      width: calc(33% - 1.5rem);
    }
  }

  .paymentPeriod {
    height: 30rem;
    margin: 1.5rem 0;
  }

  .crossingDensity {
    height: 30rem;
  }
  .peopleFlow-peopleFlowRanking,
  .passagewayStatistics-passagewayStatisticsRanking {
    display: flex;
    justify-content: space-between;
    height: 25rem;
    margin: 1.5rem 0;

    .peopleFlow,.passagewayStatistics {
      width: calc(65% - 1.5rem);
    }

    .peopleFlowRanking,.passagewayStatisticsRanking {
      width: 35%;
      padding: 1rem 0.63rem !important;
    }
  }
}
</style>
